import { useCookies } from '@vueuse/integrations/useCookies'

const cookie = useCookies()
const tokenkey = 'x-token'

//获取token
export function gettoken(){
    return cookie.get(tokenkey)
}

//设置token
export function settoken(token){
    return cookie.set(tokenkey,token)
}


//清除token
export function deletetoken(){
    return cookie.remove(tokenkey)
}