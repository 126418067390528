// import axios from "axios"
import axios from "axios";
import { showtosk } from '../utils/msg.js'
import { gettoken } from '../utils/auth.js'

const service = axios.create({
  baseURL: "",
  timeout: 30000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  const token = gettoken()
  if (token) {
    config.headers.Authorization = 'Bearer' + ' ' + token
  }
    return config
}, function (err) {
    console.log(err);
})


//响应拦截器
service.interceptors.response.use(function (response) {
  return response.data;
}, function (err) {
  console.log(err)
  showtosk(err.response.data,'error','Error')
  return Promise.reject(err)
})
export default service