import { ElNotification } from 'element-plus'
import { ElMessage } from 'element-plus'

export function showtosk(message,type='success',title='Success'){
    ElNotification({
        title,
        message,
        type,
        duration: 2000
    })
}

export function showmsg(message,type='success'){
    ElMessage({
        message,
        type,
    })
}