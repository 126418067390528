import axios from "./axios"
//登录
export const login = function login(username, password) {
    return axios.post("/api/token/", {
        username,
        password
    })
}

//注册
export const regist = function regist(data) {
    return axios.post("/api/regeister/", {
       ...data
    })
}


// 获取发布链接
export const getplatformurl = function getplatformurl(page) {
    if (page){
        return axios.get(`/api/publi/?page=${page}`)
    }else {
        return axios.get('/api/publi/')
    }
}


// 获取自由链接
export const getmyurl = function getmyurl(page) {
    if (page){
        return axios.get(`/api/ownplat/?page=${page}`)
    }else {
        return axios.get('/api/ownplat/')
    }
}

// 获取合作链接
export const getpublishingplat = function getpublishingplat(page) {
    if (page){
        return axios.get(`/api/publishingplat/?page=${page}`)
    }else {
        return axios.get('/api/publishingplat/')
    }
}

// 获取文件列表
export const getfiles = function getfiles(url,page) {
    if(url){
        if (page){
            return axios.get(`/api/${url}/?page=${page}`)
        }else {
            return axios.get(`/api/${url}/`)
        }
    }else {
        return
    }
}

// 获取ppt
export const getppt = function getppt(description) {
    return axios.get(`/api/ppt/?description=${description}`)
}

// 获取视频
export const getvideo = function getvideo(description) {
    return axios.get(`/api/lawvideo/?description=${description}`)
}


export const getDanweis = function getDanweis() {
    return axios.get('/api/publishedLink/')
}

export const getfileurl = function getfileurl(id,password,path) {
    return axios.get(`/api/${path}/${id}/password_check/?password=${password}`)
}
// 获取轮播图
export const getSwiper = function getSwiper() {
    return axios.get(`/api/imagex/`)
}
