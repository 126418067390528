import router from "@/router";
import { gettoken } from './auth.js'
import {showtosk} from './msg'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'// nprogress样式文件

//全局前置守卫
router.beforeEach((to, from,next)=>{
    NProgress.start();
    // 未登录
    const token = gettoken()
    if(!token && to.path !== '/'){
        showtosk('请登录后再访问','error','Error')
        return next({path:'/'})
    }
    //已登录强制回到刚才的页面
    // if(token && to.path == '/login'){
    //     showtosk('请勿重复登录','success','Success')
    //     return next({path:from.path ? from.path : '/' })
    // }
    next()
})



//当路由跳转结束后
router.afterEach(() => {
    // 关闭进度条
    NProgress.done()
})