import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElConfigProvider } from 'element-plus';
import 'element-plus/es/components/config-provider/style/css'

import locale from 'element-plus/lib/locale/lang/zh-cn';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_router_view)])]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};