import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {permission} from '@/utils/permission'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import {
    // create naive ui
    create,
    // component
    NButton,
} from 'naive-ui'

const naive = create({
    components: [NButton]
})

NProgress.configure({
    easing: 'ease',
    speed: 500,
    showSpinner: false,
    trickleSpeed: 200,
    minimum: 0.3
})

createApp(App).use(store).use(router).use(NProgress).use(permission).use(naive).mount('#app')
