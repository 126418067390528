import {createRouter, createWebHashHistory} from 'vue-router'
import login from '../views/login'
import index from '@/views/home'
import platform from '@/views/Platform'
import television from '@/views/television'
import PublishLink from '@/views/PublishLink'
import culture from '@/views/culture'
import medium from '@/views/medium'
import kitchen from '@/views/kitchen'
import fileData from '@/views/fileData'
import notFound from "@/views/NotFound";


const routes = [
    {
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/index',
        name: 'index',
        component: index
    },
    {
        path: '/Platform',
        name: 'Platform',
        component: platform
    },
    {
        path: '/television',
        name: 'television',
        component: television
    },
    {
        path: '/PublishLink',
        name: 'PublishLink',
        component: PublishLink
    },
    {
        path: '/culture',
        name: 'culture',
        component: culture
    }, {
        path: '/medium',
        name: 'medium',
        component: medium
    },
    {
        path: '/kitchen',
        name: 'kitchen',
        component: kitchen
    },
    {
        path: '/fileData',
        name: 'fileData',
        component: fileData
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: notFound
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
