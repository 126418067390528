import "core-js/modules/es.array.push.js";
import { reactive, ref } from 'vue';
import { Search, User, Lock } from '@element-plus/icons-vue';
import { login, regist } from "../utils/request.js";
import { useRouter } from 'vue-router';
import { showmsg, showtosk } from '../utils/msg.js';
import { settoken, gettoken } from '../utils/auth.js';
import { useStore } from 'vuex';
export default {
  components: {
    Search,
    User,
    Lock
  },
  setup() {
    //usecookie实例化
    const route = useRouter();
    const lodding = ref(false);
    const lodding2 = ref(false);
    const store = useStore();
    const token = gettoken();
    //form表单
    const form = reactive({
      username: '',
      password: ''
    });
    //form表单
    const form2 = reactive({
      username: '',
      password: '',
      password2: ''
    });
    const show = ref(true);
    const shows = () => {
      show.value = !show.value;
    };

    //登录按钮
    function onSubmit() {
      fromRef.value.validate(valid => {
        if (!valid) {
          return false;
        }
        // if (token) {
        //   showtosk('请勿重复登录', 'error', 'Error')
        //   route.push('/')
        //   return
        // }
        lodding.value = true;
        login(form.username, form.password).then(res => {
          console.log(res);
          if (res.code === 2000) {
            localStorage.clear();
            showtosk('登录成功', 'success', 'Success');
            // 存储cookie
            settoken(res.data);
            localStorage.setItem('userinfo', JSON.stringify(res.data));
            route.push('/index');
          }
        }).finally(() => {
          lodding.value = false;
        });
      });
    }

    //注册按钮
    function onSubmit2() {
      fromRef2.value.validate(valid => {
        if (!valid) {
          return false;
        }
        lodding2.value = true;
        regist(form2).then(res => {
          if (res.code === 2000) {
            showtosk('注册成功，请前去登录');
          } else {
            showtosk(`注册失败:${res.msg}`, 'error', 'error');
          }
        }).finally(() => {
          lodding2.value = false;
        });
      });
    }
    const fromRef = ref(null);
    const fromRef2 = ref(null);

    //表单验证
    const rules = {
      username: [{
        required: true,
        message: '用户名不能为空',
        trigger: 'blur'
      }, {
        min: 5,
        max: 11,
        message: '请输入5~11位长度用户名',
        trigger: 'blur'
      }],
      password: [{
        required: true,
        message: '密码不能为空',
        trigger: 'blur'
      }, {
        min: 6,
        max: 11,
        message: '请输入6~16位长度的密码',
        trigger: 'blur'
      }]
    };
    const rules2 = {
      username: [{
        required: true,
        message: '用户名不能为空',
        trigger: 'blur'
      }, {
        min: 5,
        max: 11,
        message: '请输入5~11位长度用户名',
        trigger: 'blur'
      }],
      password: [{
        required: true,
        message: '密码不能为空',
        trigger: 'blur'
      }, {
        min: 6,
        max: 11,
        message: '请输入6~16位长度的密码',
        trigger: 'blur'
      }],
      password2: [{
        required: true,
        message: '确认密码不能为空',
        trigger: 'blur'
      }, {
        min: 6,
        max: 11,
        message: '请输入6~16位长度的密码',
        trigger: 'blur'
      }]
    };
    return {
      form,
      form2,
      onSubmit,
      onSubmit2,
      rules,
      fromRef,
      fromRef2,
      lodding,
      show,
      shows,
      rules2
    };
  }
};